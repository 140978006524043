// Like functionality for articles
// Depends on upvote plugin
// https://github.com/doublesecretagency/craft-upvote

'use strict';

(function (window) {
  var SELECTOR = '[data-upvote-element]';
  var TOGGLE_CLASS = 'hidden';

  function initUpvote() {
    // only run on pages with upvote elements
    if (!document.querySelector(SELECTOR)) {
      return;
    }

    // load upvote scripts if needed
    if (!window.upvote && window.BLUME.upvote && window.loadJS) {
      for (var i = 0; i < window.BLUME.upvote.jsFiles.length; i++) {
        if (i < window.BLUME.upvote.jsFiles.length - 1) {
          loadJS(window.BLUME.upvote.jsFiles[i], true);
        } else {
          loadJS(
            window.BLUME.upvote.jsFiles[i],
            function () {
              runUpvote();
            },
            true
          );
        }
      }

      // run upvote
    } else {
      runUpvote();
    }
  }

  function runUpvote() {
    if (window.upvote) {
      upvote.actionUrl = window.BLUME.upvote.actionUrl;
      var els = document.querySelectorAll(SELECTOR);
      if (els.length) {
        upvote.pageSetup();
        els.forEach(function (el) {
          el.classList.remove(TOGGLE_CLASS);
        });
      }
    }
  }

  initUpvote();
})(this);
