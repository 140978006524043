// Startup Title Highlight
//
// Scan all text nodes in the list item for the presence
// of Startup title and for the first occurrence highlight
// the name
//

(function () {
  'use strict';

  const contentSelector = '[data-highlight-term]';
  const excludeChildrenOf = [
    'button',
    'select',
    'summary',
    'label',
    'style',
    'script',
    'noscript',
    'template',
  ];

  function initStartupTitles() {
    // Select all elements with data-highlight-term attribute
    const startupCaptions = document.querySelectorAll(contentSelector);

    // Process each element
    startupCaptions.forEach((element) => {
      const startupTitle = element.dataset.highlightTerm;
      if (!startupTitle) {
        return;
      }

      for (const node of findTextNodes(element)) {
        const escapedStartupTitle = escapeRegExp(startupTitle);
        const regex = new RegExp(
          `(?:\\b|(?<!\\w))${escapedStartupTitle}(?!['\\w])`,
          'u'
        );
        const match = regex.exec(node.nodeValue);
        if (match) {
          const term = match[0];
          node.splitText(match.index + term.length);

          // split text at the term start boundary
          const ignore = node.splitText(match.index);

          // create term link
          const termBold = document.createElement('b');
          termBold.classList.add(
            'font-title',
            'text-lg',
            'md:text-xl',
            'font-semibold',
            'text-navy-light'
          );
          termBold.appendChild(document.createTextNode(term));

          // add term to the text
          node.parentNode.replaceChild(termBold, ignore);
          // Stop after the first replacement
          break;
        }
      }
    });
  }

  // locate all text-only nodes inside the content
  function findTextNodes(element) {
    const nodes = [];
    const walker = document.createTreeWalker(
      element,
      NodeFilter.SHOW_TEXT,
      (node) =>
        node.parentNode.closest(excludeChildrenOf.join(','))
          ? NodeFilter.FILTER_SKIP
          : NodeFilter.FILTER_ACCEPT
    );

    while (walker.nextNode()) {
      nodes.push(walker.currentNode);
    }

    return nodes;
  }

  // Helper function to escape special regex characters
  // Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions#escaping
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  initStartupTitles();
})(this);
